.main-footer {
  -webkit-transition: -webkit-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  
  position: fixed;
  bottom: 0px;
  left: 230px;
  z-index: 820;
  width: calc(100% - 230px);;
}
.footclr{
  color:#2b3245; 
}

.layout-top-nav .main-footer {
  margin-left: 0;
}
@media (max-width: 767px) {
  .main-footer {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .main-footer {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .sidebar-open .main-footer {
    -webkit-transform: translate(230px, 0);
    -ms-transform: translate(230px, 0);
    -o-transform: translate(230px, 0);
    transform: translate(230px, 0);
  }
}
.main-footer {
  font-size: 14px;
  background: #fff;
  padding: 15px;
  color: #444;
  border-top: 1px solid #d2d6de;
}
.comnm{
  color: #337ab7; 
}
.footerTwoMargin{
      /*margin-left: 80px;*/
}