.main-sidebar {
   position: absolute;
   top: 0px;
   left: 0;
   padding-top: 50px;
   min-height: 600px;
   height: 100vh;
   width: 230px;
   z-index:2100;
   -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
   -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
   -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
   transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;   
   background-color: #222d32;
}


.side-menu-baricon{
   position: absolute;
   top: 15px;
   left: 250px;
   transition: 0.5s all;
}

.main-sidebar ul{
   padding: 0;
}
.main-sidebar li{
   height: 50px;
   border-bottom: 1px slid #eee;
   font-size: 16px;
   padding-left: 18%;
   padding-top: 10px;
}

.main-sidebar li:hover{
   background-color: #666;
}

.main-sidebar li:hover .main-sidebar li{
   padding-left: 30%;
}

.sidebarMenuTitle{
   display: inline-block;
   transition: 0.3s all;
}

.hideSidebarMenuTitle{
   display: none;
   width: 0px;
   padding-left: ;
   transition: 0.1s all;
}

.main-sidebar li:hover .hideSidebarMenuTitle{
   display: inline-block;
   background-color: #000;
   width: 200px;
   height: 50px;
   margin-top: -10px;
   margin-left: 17px;
   padding-top: 15px;
   padding-left: 15px;
   color: #fff;   
   position: absolute;
}

.main-sidebar a{
   color: #fff;
   text-decoration: none;
   transition: 0.1s all;
}

.main-sidebar a:hover{
   color: #5c9fbf;
   font-weight: bold;
}

.logoWrapper1{
   position: absolute;
   top: 0px;
   overflow: hidden;
   height: 50px;
   padding-top: 5px;
   text-align: center;
   background-color: #fff !important ;
}
.periLogo{
   width: 90%;
   margin: auto;
}