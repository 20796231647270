/* ************ Start Media Query ************ */

@media (max-width: 359px) {
  .main-page-wrapper {
    margin-left: 0px;
    max-width: 100% !important;
  }
  .content {
    padding: 0px;
    margin: 0px;
  }
  .pageContent {
    margin-top: 0px;
  }
  .width-100vw {
    width: 100vw !important;
    overflow: auto !important;
  }
  .saveTemplateWrapper {
    margin-top: 60px;
  }
  .header-order-details {
    font-size: small;
  }
  .logoWrapper {
    position: relative;
    top: 0px;
    left: 0px;
  }

  .contactdeilsmgId {
    padding-top: 0px;
    font-size: small !important;
  }
  #logInBtn {
    font-size: 10px;
  }

  .sidertemplatebar {
    min-height: 250px;
  }
  .margin-top-30 {
    margin-top: 0px;
  }
  .ummodallftmg{
    margin-top: 100px;
  }
  .modal-title-custom{
    margin-top: 0px;
    padding-top: 0px;
  }
  .close-btn{
    margin-top: 0px!important;
    padding-top: 0px!important;
  }
}

@media (min-width: 360px) and (max-width: 410px) {
  .main-page-wrapper {
    margin-left: 0px;
    max-width: 100% !important;
  }
  .content {
    padding: 0px;
    margin: 0px;
  }
  .pageContent {
    margin-top: 0px;
  }
  .width-100vw {
    width: 100vw !important;
    overflow: auto !important;
  }
  .saveTemplateWrapper {
    margin-top: 60px;
  }
  .header-order-details {
    font-size: small;
  }
  .logoWrapper {
    position: relative;
    top: 0px;
    left: 0px;
  }
  .contactdeilsmgId {
    padding-top: 0px;
    font-size: small !important;
  }
  #logInBtn {
    font-size: 10px;
  }
  .sidertemplatebar {
    min-height: 250px;
  }
  .margin-top-30 {
    margin-top: 0px;
  }
  .ummodallftmg{
    margin-top: 100px;
  }
  .modal-title-custom{
    margin-top: 0px;
    padding-top: 0px;
  }
  .close-btn{
    margin-top: 0px!important;
    padding-top: 0px!important;

  }
}

@media (min-width: 411px) and (max-width: 576px) {
  .main-page-wrapper {
    margin-left: 0px;
    max-width: 100% !important;
  }
  .content {
    padding: 0px;
    margin: 0px;
  }
  .pageContent {
    margin-top: 0px;
  }
  .width-100vw {
    width: 100vw !important;
    overflow: auto !important;
  }
  .saveTemplateWrapper {
    margin-top: 60px;
  }
  .header-order-details {
    font-size: small;
  }
  .loginCase {
    /*margin-top: 25vh;*/
  }
  .logoWrapper {
    position: relative;
    top: 0px;
    left: 0px;
  }
  .contactdeilsmgId {
    padding-top: 0px;
    font-size: small !important;
  }

  #logInBtn {
    font-size: 10px;
  }
  .sidertemplatebar {
    min-height: 250px;
  }
  .margin-top-30 {
    margin-top: 0px;
  }
  .ummodallftmg{
    margin-top: 100px;
  }
  .modal-title-custom{
    margin-top: 0px;
    padding-top: 0px;
  }
  .close-btn{
    margin-top: 0px!important;
    padding-top: 0px!important;

  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .main-page-wrapper {
    margin-left: 0px;
    max-width: 100% !important;
  }
  .content {
    padding: 0px;
    margin: 0px;
  }
  .pageContent {
    margin-top: 0px;
  }
  .width-100vw {
    width: 100vw !important;
    overflow: auto !important;
  }
  .saveTemplateWrapper {
    margin-top: 60px;
  }
  .header-order-details {
    font-size: small;
  }
  .logoWrapper {
    position: relative;
    top: 0px;
    left: 0px;
  }
  .sidertemplatebar {
    min-height: 250px;
  }
  .margin-top-30 {
    margin-top: 0px;
  }
  .ummodallftmg{
    margin-top: 100px;
  }
  .modal-title-custom{
    margin-top: 0px;
    padding-top: 0px;
  }
  .close-btn{
    margin-top: 0px!important;
    padding-top: 0px!important;

  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .main-page-wrapper {
    margin-left: 0px;
    max-width: 100% !important;
  }
  .content {
    padding: 0px;
    margin: 0px;
  }
  .pageContent {
    margin-top: 0px;
  }
  .width-100vw {
    width: 100vw !important;
    overflow: auto !important;
  }
  .saveTemplateWrapper {
    margin-top: 60px;
  }
  .header-order-details {
    font-size: small;
  }
  .logoWrapper {
    position: relative;
    top: 0px;
    left: 0px;
  }
  .contactdeilsmgId {
    padding-top: 0px;
    font-size: small !important;
  }

  #logInBtn {
    font-size: 10px;
  }
  .margin-top-30 {
    margin-top: 0px;
  }
  .ummodallftmg{
    margin-top: 100px;
  }
  .modal-title-custom{
    margin-top: 0px;
    padding-top: 0px;
  }
  .close-btn{
    margin-top: 0px!important;
    padding-top: 0px!important;

  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .logoWrapper {
    position: relative;
    top: 0px;
    left: 0px;
    margin-top: 100px;
  }
  .periscopeLogo {
    margin-top: 80px;
  }
  .margin-top-30 {
    margin-top: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  #logInBtn {
    font-size: 10px;
  }
  .margin-top-30 {
    margin-top: -30px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  #logInBtn {
    font-size: 10px;
  }

  .margin-top-30 {
    margin-top: -30px;
  }


}

@media (min-width: 1280px) and (max-width: 1365px) {
  #logInBtn {
    font-size: 10px;
  }
  .margin-top-30 {
    margin-top: -30px;
  }
}

@media (min-width: 1366px) and (max-width: 1439px) {
  #logInBtn {
    font-size: 16px;
  }

  .margin-top-30 {
    margin-top: -30px;
  }
}

@media (min-width: 1440px) and (max-width: 1679px) {
}

@media (min-width: 1680px) and (max-width: 1919px) {
}

@media (min-width: 1920px) and (max-width: 2559px) {
}

@media (min-width: 2560px) {
}

@media print {
}
