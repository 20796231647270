.bulkEmployeeVerif ul li{text-align: left;list-style: disc;}
.bulkEmployeeImg{height: 100px;}
.bulkEmployeeImg img{height: 115px;margin-bottom: 1px;}
.bulkEmployeeImg a{font-size: 13px;color: #00b8ff!important}
.bulkEmployeeContent{/*margin-bottom: 50px;*/margin-top: 4px;background-color: #f6f6f7;padding-top: 20px;padding-bottom: 15px;}
.bulkEmployeeFile p span, .bulkEmployeeFile p span:hover, .bulkEmployeeFile p span:focus,
.bulkEmployeeFile p span:active{background-color: #00b8ff;color: #fff;padding: 10px 50px;margin-left: 10px;cursor: pointer;border-radius: 0px;}
.bulkuploadFileouter{margin-top: 2%;box-shadow: 0px 0px 6px #999;padding: 1%;}
.h4HeadMarg{margin-right: 20px;}
.aHeadMarg{margin-right: 10px;}
.NOpadding{padding:0;}
.marginAll{margin: 2%;}


.bulksubmitbtn{
	background-color: #337ab7 ! important;
	color: #ffffff;
}