
.mailtext:hover{
  color: #fff;
}
.mailtext{
  color: #fff;
  overflow: hidden;
  position: absolute; 
  text-overflow: ellipsis;
  padding-top: 5px;
  white-space: nowrap;
}
/*@media only screen and (width: 1200px) {
  #headerid {
    width: 82.0% !important;
  }
   #dashbordid {
    width: 80% !important;
  }
}*/

.dropmailtext{
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
}
.colorbox{
  background: #367EA8;
  height: 50px
}
.fntC:hover {
  color: #333;
}
.fntC {
  margin-top: 2px;
  font-size: 12px;
  color: #333;
}

.hover:hover{
    cursor: pointer;
}
.img{
    position: absolute;
    top: 15px;
    height: 25px;
}


#mySidenav .closebtn:hover {
  background-color: #fff;
  border:0px;
  color: #333;
}
#mySidenav .closebtn i:hover {

  box-shadow: 0px 0px 10px #aaa;
}
#mySidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 14px;
  margin-left: 50px;
}

#mysidenav{
  transition: all 0.8s ease 0s;
  z-index: 100;
}
.showme{ 
  display: none;
  position: absolute;
  top: 45px;
  right: 10%;
}
.showhim{
  display : block;
}
.showhim .hover{
  background-color: #0d5c8a;
}
.logoutAct{
  z-index: 999;
}

.arrow-up {
  margin-left: 84%;
  width: 0;
  height: 0;
  margin-top: 50px;
}
.bell_Icon{
  font-size: 15px;
    /* font-weight: bold; */
    padding-top: 12px;
    padding-bottom: 12px;
    color: #fff;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    cursor: pointer;
    padding-left:5px;
}
.logoutDiv{
  margin-bottom: 35px;
}

#headerid {
  position: fixed;
  width: 82.8%;
  z-index: 5;
  right: 0;
  height: 50px;
  background-color: #3c8dbc;
  transition: all .8s;
  box-shadow: 0 10px 14px rgba(92,141,255,.2);
  transition-duration: 0.4s;
}

#dashbordid{
  position: absolute;
  width: 82.9%;
  right: 0px;
  transition: all 0.4s;
  top:50px;
  }

.fs12{
  font-size: 12px;
}
.hoverText{
  font-size: 12px;
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 20px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin: -5px;
}
.headicon{
    font-size: 14px;
    padding-top: 18px;
    color: #fff;
    cursor: pointer;
}
.dropbtn {
    color: #666;
    font-size: 16px;
    border: none;
    cursor: pointer;
}
.dropbtn {
    color: #666;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-bottom: 0px;

}
.dropdown {
    position: relative;
    display: inline-block;
    z-index: auto;
}

.dropdown-content {
    display: none;
    position: absolute;
    
    min-width: 240px !important;
    box-shadow: 0px 8px 8px 0px #eee;
    z-index: 1;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
}

.dropdown-content .deleteNotif:hover {background-color: #f1f1f1;
    cursor:pointer;}
.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .dropbtn { color:#666;}

.dropdown-content .deleteNotif:hover { 
    cursor: pointer;
    color: #666666;
    padding: 7px 7px;
    text-decoration: none;
    display: block;
    border-radius: 3px;}
.dropdown:hover .dropdown-content {display: block; }
.dropdown:hover .dropbtn { color:#666;}

.pageHeader{
    height: 50px;
}
.padd0{
  padding: 0px;
}
.marTop{
  margin-top: 16px;
}
.btnDiv{
  cursor: auto;
  margin-top: 10px; 
  margin-bottom: 10px;
}
.marLeft{
  margin-left: 100px;
  padding-bottom: 5px;
}
.dropdown:hover .dropbtn{
  background: transparent!important;
}
.nomargin{
  margin: 0px;
}
.topmargin{
  margin-top: 10px;
  width: 30px;
  margin-left: 10px;
}
.topmargin:hover{
  background: none;
}
.nopadd{
padding: 5px 5px !important;
}
.btnpadd{
  padding: 0px 0px !important;
  background: none !important;
}

.borderline{
  border: 1px solid #ddd;
  margin: 10px  0px !important;
}

.profilebtn{
  /*background: var(--blue-color );*/
  color: #fff;
  background-color: #3c8dbc;
  font-weight: 200;
  border: 1px solid #ddd6 !important;
  font-size: 13px;
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
 /* border-radius: 23px;*/
  outline: 0;
  border: 1px solid #aaa;
}
.profilebtn:hover{
  background:#ddd ;
  color: #666;
  border: 1px solid #aaa !important;
}
.logoutbtn{
  /*background:  rgb(221, 75, 57);*/
  background-color: #3c8dbc;

  color: #fff;
  font-weight: 200;
  border: 1px solid #ddd6 !important;
  font-size: 13px;
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  /*border-radius: 23px;*/
  outline: 0;
  border: 1px solid #aaa;
}
.logoutbtn:hover{
  background:#ddd ;
  color: #666;
  border: 1px solid #aaa !important;

}
.profileTitle{
  cursor: pointer;
}

.hover:hover i{
    /* color: #fff !important; */
}
.img{
    position: absolute;
    top: 15px;
    height: 25px;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 900;
  top: 50px;
  right: 0;
  background-color: #222d32;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 0px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  color: #333;
  display: block;
  background-color:#fff;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
  background-color: #303F9F;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav a {font-size: 18px;}
}

#mysidenav{
  transition: all 0.8s ease 0s;
  z-index: 100;

}
.fontB{
  color: #333;
}
.hover{
  height: 45px;
}
.msgnotification{
  margin-bottom: 10px;
  background-color: #eeeeee;
  padding-top: 15px;
  padding-bottom: 8px;
  border: 1px solid #3333;
}
 .bellicon{
  text-align: center;
 }
 .notification{
  margin-top: 11px;
  color: #333;
 }
.notification .badge {
  position: absolute;
  top: 1px;
  /*right: 6px;*/
  padding: 4px 7px;
  border-radius: 50%;
  background-color: red!important;
  color: white;
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
  position: absolute;
  right: -16px;
  top: 1px;
  z-index: 100;
}
.bellnotification{ 
  display: none;
  padding: 5px;
  overflow: scroll;
  position: absolute;
  height: 350px;
  width: 300px;
  left:-60px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-top: -10px;
  background: #fff;
  color: #666;
}
.user-notification{
  color: #0c0c0c;
  font-weight: 600;
  font-size: 16px;
}
.user-footer {
    position: absolute;
    min-height: 236px;
    width: 275px;
    left:-40px;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    margin-top: 1px;
    background: #fff;
}
.headerImageContainer{
  height:175px;
  background-color: #3c8dbc;
  text-align: center;
  padding-top: 10px;
  color: #fff;
  cursor: auto;
}
.circleBorder{

}
.user-closernoti{
  color: #0c0c0c;
  font-weight: 600;
}

.user-closernoti p{
  font-size: 12px;
}
.msgborderbtm{
  border-bottom: 1px solid #eee;
  font-size: 12px;
  font-weight: 400;
  /*padding: 5px;*/
  color: #666;
}

.headereffect
{
  width: 96.3%!important;
}
.dashboardeffect
{
  width: 96.3%!important;
}
.userIcon{
  height: 25px;
  width:25px;
}
.onHoverEffect:hover{
  background-color: #367EA8 !important;
  margin-top: -5px;
}
@media only screen and (width: 1400px) {
  #headerid {
    width: 84.6% ;
  }
   #dashbordid {
    width: 84.6% ;
  }
  .headereffect
  {
    width: 95.8%;
  }
  .dashboardeffect
  {
    width: 95.8%;
  }
}

@media only screen and (width: 1200px) {
  #headerid {
    width: 81.6% ;
  }
   #dashbordid {
    width: 81.6% ;
  }
  .headereffect
  {
    width: 93%;
  }
  .dashboardeffect
  {
    width: 93%;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1399px) {
  #headerid {
    width: 83.8% ;
  }
   #dashbordid {
    width: 83.8% ;
  }
  .headereffect
  {
    width: 93.2%;
  }
  .dashboardeffect
  {
    width: 93.2%;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1300px) {
  #headerid {
    width: 83% ;
  }
   #dashbordid {
    width: 83% ;
  }
  .headereffect
  {
    width: 93%;
  }
  .dashboardeffect
  {
    width: 93%;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1500px) {
  #headerid {
    width: 85% ;
  }
   #dashbordid {
    width: 85% ;
  }
  .headereffect
  {
    width: 93%;
  }
  .dashboardeffect
  {
    width: 93%;
  }
}
.fntC1{
    font-size: 12px;
    color: #fff;
    margin-right: 9px;
}


/************************************************************/


/*@media only screen and (width: 1200px) {
  #headerid {
    width: 82.0% !important;
  }
   #dashbordid {
    width: 80% !important;
  }
}*/

.dropmailtext{
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
}
.colorbox{
  background: #367EA8;
  height: 50px
}
.fntC:hover {
  color: #333;
}
.fntC {
  margin-top: 2px;
  font-size: 12px;
  color: #333;
}

.hover:hover{
    /*background-color: #fff;*/
    cursor: pointer;
}
.img{
    position: absolute;
    top: 15px;
    height: 25px;
}


#mySidenav .closebtn:hover {
  background-color: #fff;
  border:0px;
  color: #333;
}
#mySidenav .closebtn i:hover {

  box-shadow: 0px 0px 10px #aaa;
}
#mySidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 14px;
  margin-left: 50px;
}

#mysidenav{
  transition: all 0.8s ease 0s;
  z-index: 100;
}
.showme{ 
  display: none;
}
.showhim{
  display : block;
}
.logoutAct{
  z-index: 999;
}

.arrow-up {
  margin-left: 84%;
  width: 0;
  height: 0;
  margin-top: 50px;
}
.logoutDiv{
  margin-bottom: 35px;
}
.mailtext:hover{
  color: #fff;
}
.mailtext{
  overflow: hidden;
  position: absolute; 
  text-overflow: ellipsis;
  padding-top: 5px;
  white-space: nowrap;
}
#headerid {
  position: fixed;
  width: 82.8%;
  z-index: 5;
  right: 0;
  height: 50px;
  background-color: #3c8dbc;
  transition: all .8s;
  box-shadow: 0 10px 14px rgba(92,141,255,.2);
  transition-duration: 0.4s;
}

#dashbordid{
  position: absolute;
  width: 82.9%;
  right: 0px;
  transition: all 0.4s;
  top:50px;
  }

.fs12{
  font-size: 12px;
}
.hoverText{
  font-size: 12px;
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  cursor: pointer;
  white-space: nowrap;
  margin: -5px;
}
.headicon{
    font-size: 14px;
    padding-top: 18px;
    color: #fff;
    cursor: pointer;
}
.dropbtn {
    color: #666;
    font-size: 16px;
    border: none;
    cursor: pointer;
}
.dropbtn {
    color: #666;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-bottom: 0px;

}
.dropdown {
    position: relative;
    display: inline-block;
    z-index: auto;
}

.dropdown-content {
    display: none;
    position: absolute;
    
    min-width: 240px !important;
    box-shadow: 0px 8px 8px 0px #eee;
    z-index: 1;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
}

.dropdown-content .deleteNotif:hover {background-color: #f1f1f1;
    cursor:pointer;}
.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .dropbtn {background-color: #fff; color:#666;}

.dropdown-content .deleteNotif:hover { 
    background-color: #f1f1f1;
    cursor: pointer;
    color: #666666;
    padding: 7px 7px;
    text-decoration: none;
    display: block;
    border-radius: 3px;}
.dropdown:hover .dropdown-content {display: block; background: #fff;}
.dropdown:hover .dropbtn {background-color: #fff; color:#666;}

.pageHeader{
    height: 50px;
}
.padd0{
  padding: 0px;
}
.marTop{
  margin-top: 16px;
}
.btnDiv{
  margin-top: 10px; 
  margin-bottom: 10px;
}
.marLeft{
  padding-bottom: 5px;
}
.dropdown:hover .dropbtn{
  background: transparent!important;
}
.nomargin{
  margin: 0px;
}
.topmargin{
  margin-top: 10px;
  width: 30px;
  margin-left: 10px;
}
.topmargin:hover{
  background: none;
}
.nopadd{
padding: 5px 5px !important;
}
.btnpadd{
  padding: 0px 0px !important;
  background: none !important;
}

.borderline{
  border: 1px solid #ddd;
  margin: 10px  0px !important;
}

.profilebtn{
  /*background: var(--blue-color );*/
  color: #666;
  background-color: #ddd6;
  font-weight: 200;
  border: 1px solid #ddd6 !important;
  font-size: 13px;
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
 /* border-radius: 23px;*/
  outline: 0;
  border: 1px solid #aaa;
}
.profilebtn:hover{
  background:#ddd ;
  color: #666;
  border: 1px solid #aaa !important;
}
.logoutbtn{
  /*background:  rgb(221, 75, 57);*/
  background-color: #ddd6;

  color: #666;
  font-weight: 200;
  border: 1px solid #ddd6 !important;
  font-size: 13px;
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  /*border-radius: 23px;*/
  outline: 0;
  border: 1px solid #aaa;
}
.logoutbtn:hover{
  background:#ddd ;
  color: #666;
  border: 1px solid #aaa !important;

}
.profileTitle{
  cursor: pointer;
}

.hover:hover i{
    /* color: #fff !important; */
}
.img{
    position: absolute;
    top: 15px;
    height: 25px;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 900;
  top: 50px;
  right: 0;
  background-color: #222d32;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 0px;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  color: #333;
  display: block;
  background-color:#fff;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
  background-color: #303F9F;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav a {font-size: 18px;}
}

#mysidenav{
  transition: all 0.8s ease 0s;
  z-index: 100;

}
.fontB{
  color: #333;
}
.hover{
  height: 45px;
}
.msgnotification{
  margin-bottom: 10px;
  background-color: #eeeeee;
  padding-top: 15px;
  padding-bottom: 8px;
  border: 1px solid #3333;
}
 .bellicon{
  text-align: center;
 }
 .notification{
  margin-top: 11px;
  color: #333;
 }
.notification .badge {
  position: absolute;
  top: 5px;
  right: 6px;
  padding: 4px 7px;
  border-radius: 50%;
  background-color: red!important;
  color: white;
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

.user-notification{
  color: #0c0c0c;
  font-weight: 600;
  font-size: 16px;
}
.user-footer {
    position: absolute;
    min-height: 236px;
    width: 275px;
    left:0px;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    margin-top: 1px;
    background: #fff;
}
.headerImageContainer{
  height:175px;
  background-color: #3c8dbc;
  text-align: center;
  padding-top: 10px;
  color: #fff;
}
.circleBorder{

}
.user-closernoti{
  color: #0c0c0c;
  font-weight: 600;
}

.user-closernoti p{
  font-size: 12px;
}
.msgborderbtm{
  border-bottom: 1px solid #eee;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
}

.headereffect
{
  width: 96.3%!important;
}
.dashboardeffect
{
  width: 96.3%!important;
}
.userIcon{
  height: 25px;
  width:25px;
}
.onHoverEffect:hover{
  background-color: #367EA8 !important;
  margin-top: -5px;
}
@media only screen and (width: 1400px) {
  #headerid {
    width: 84.6% ;
  }
   #dashbordid {
    width: 84.6% ;
  }
  .headereffect
  {
    width: 95.8%;
  }
  .dashboardeffect
  {
    width: 95.8%;
  }
}

@media only screen and (width: 1200px) {
  #headerid {
    width: 81.6% ;
  }
   #dashbordid {
    width: 81.6% ;
  }
  .headereffect
  {
    width: 93%;
  }
  .dashboardeffect
  {
    width: 93%;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1399px) {
  #headerid {
    width: 83.8% ;
  }
   #dashbordid {
    width: 83.8% ;
  }
  .headereffect
  {
    width: 93.2%;
  }
  .dashboardeffect
  {
    width: 93.2%;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1300px) {
  #headerid {
    width: 83% ;
  }
   #dashbordid {
    width: 83% ;
  }
  .headereffect
  {
    width: 93%;
  }
  .dashboardeffect
  {
    width: 93%;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1500px) {
  #headerid {
    width: 85% ;
  }
   #dashbordid {
    width: 85% ;
  }
  .headereffect
  {
    width: 93%;
  }
  .dashboardeffect
  {
    width: 93%;
  }
}




.shortlogo {
    width: 45px;
    padding-top: 5px !important;
}
@font-face{
  font-family:'MYRIADPROREGULAR';
  /*src: url("/fonts/MYRIADPROREGULAR.ttf");*/
}

@font-face{
  font-family:'MyriadPro-Bold';
 /* src: url("/fonts/MyriadPro-Bold.otf");*/
}

@font-face{
  font-family:'Myriad-Pro-Semibold';
/*  src: url("/fonts/Myriad Pro Semibold.ttf");*/
}

.newMain-header{
  background: #1b1c20!important;
}
.main-header {
  position: relative;
  max-height: 50px !important;
  z-index: 1030;
}
.main-header .navbar {
  -webkit-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
  margin-bottom: 0;
  margin-left: 230px;
  border: none;
  height: 50px !important;
  border-radius: 0;
  background: var(--blue-background) !important;
}
.layout-top-nav .main-header .navbar {
  margin-left: 0;
}
.main-header #navbar-search-input.form-control {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent;
}
.main-header #navbar-search-input.form-control:focus,
.main-header #navbar-search-input.form-control:active {
  /*border-color: rgba(0, 0, 0, 0.1);*/
  background: rgba(255, 255, 255, 0.9);
}
.main-header #navbar-search-input.form-control::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}
.main-header #navbar-search-input.form-control:-ms-input-placeholder {
  color: #ccc;
}
.main-header #navbar-search-input.form-control::-webkit-input-placeholder {
  color: #ccc;
}
.main-header .navbar-custom-menu,
.main-header .navbar-right {
  margin-top: 10px;
  float: right;
}
@media (max-width: 991px) {
  .main-header .navbar-custom-menu a,
  .main-header .navbar-right a {
    color: inherit;
    background: transparent;
  }
}
@media (max-width: 767px) {
  .main-header .navbar-right {
    float: none;
  }
  .navbar-collapse .main-header .navbar-right {
    margin: 7.5px -15px;
  }
  .main-header .navbar-right > li {
    color: inherit;
    border: 0;
  }
}

.main-header .sidebar-toggle:before {
  content: "\f0c9";
}

.main-header .sidebar-toggle:focus,
.main-header .sidebar-toggle:active {
  background: transparent;
}
.main-header .sidebar-toggle .icon-bar {
  display: none;
}
.main-header .navbar .nav > li.user > a > .fa,
.main-header .navbar .nav > li.user > a > .glyphicon,
.main-header .navbar .nav > li.user > a > .ion {
  margin-right: 5px;
}
.main-header .navbar .nav > li > a > .label {
  position: absolute;
  top: 9px;
  right: 7px;
  text-align: center;
  font-size: 9px;
  padding: 3px 3px;
  line-height: .9;
}
.main-header .logo {
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  display: block;
  float: left;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  width: 230px;
  /*font-family: 'MYRIADPROREGULAR';*/
  padding: 0 15px;
  font-weight: 300;
  overflow: hidden;
}
.main-header .logo .logo-lg {
  display: block;
  color: #fff;
}
.main-header .logo .logo-mini {
  display: none;
}
.main-header .navbar-brand {
  color: #fff;
}
@media (max-width: 767px) {
  .main-header {
    position: relative;
  }
  .main-header .logo,
  .main-header .navbar {
    width: 100%;
    float: none;
  }
  .main-header .navbar {
    margin: 0;
  }
  .main-header .navbar-custom-menu {
    float: right;
  }
}

.main-header .logo {
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  display: block;
  float: left;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  width: 230px;
  /*font-family: 'MYRIADPROREGULAR';*/
  padding: 0 15px;
  font-weight: 300;
  overflow: hidden;
}
.main-header .logo .logo-lg {
  display: block;
  color: #fff;
}
.main-header .logo .logo-mini {
  display: none;
}
.dashboardLogoOES{
  float: left;
  width: 72%;
  margin-top: 4%;
  padding: 0;
}
.dashboardLogoOESSmall{
    padding: 10px;
}
.main-header .navbar {
  -webkit-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
  margin-bottom: 0;
  margin-left: 230px;
  border: none;
  min-height: 50px;
  border-radius: 0;
}
.dropdown-menu {
  /*box-shadow: none;*/
  border-color: #eee;
}
.dropdown-menu > li > a {
  color: #777;
}
.dropdown-menu > li > a > .glyphicon,
.dropdown-menu > li > a > .fa,
.dropdown-menu > li > a > .ion {
  margin-right: 10px;
}
.dropdown-menu > .divider {
  background-color: #eee;
}
.navbar-nav > .notifications-menu > .dropdown-menu,
.navbar-nav > .messages-menu > .dropdown-menu,
.navbar-nav > .tasks-menu > .dropdown-menu {
  width: 280px;
  padding: 0 0 0 0;
  margin: 0;
  top: 100%;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li,
.navbar-nav > .messages-menu > .dropdown-menu > li,
.navbar-nav > .tasks-menu > .dropdown-menu > li {
  position: relative;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.header,
.navbar-nav > .messages-menu > .dropdown-menu > li.header,
.navbar-nav > .tasks-menu > .dropdown-menu > li.header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #ffffff;
  padding: 7px 10px;
  border-bottom: 1px solid #f4f4f4;
  color: #444444;
  font-size: 14px;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a,
.navbar-nav > .messages-menu > .dropdown-menu > li.footer > a,
.navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 12px;
  background-color: #fff;
  padding: 7px 10px;
  border-bottom: 1px solid #eeeeee;
  color: #444 !important;
  text-align: center;
}
@media (max-width: 991px) {
  .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a,
  .navbar-nav > .messages-menu > .dropdown-menu > li.footer > a,
  .navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
    background: #fff !important;
    color: #444 !important;
  }
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a:hover,
.navbar-nav > .messages-menu > .dropdown-menu > li.footer > a:hover,
.navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a:hover {
  text-decoration: none;
  font-weight: normal;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu {
  max-height: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a {
  display: block;
  white-space: nowrap;
  /* Prevent text from breaking */
  /*border-bottom: 1px solid #f4f4f4;*/
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a {
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .glyphicon,
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .fa,
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .ion {
  width: 20px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a {
  margin: 0;
  padding: 22px 15px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a.bord {
  margin: 0;
  padding: 10px 10px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > div > img {
  margin: auto 10px auto auto;
  width: 40px;
  height: 40px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 {
  padding: 0;
  margin: 0 0 0 45px;
  color: #fff;
  font-size: 15px;
  position: relative;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 > small {
  color: #999999;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > p {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 13px;
  font-size: 0.81em;
  color: #fff;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:before,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:after {
  content: " ";
  display: table;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:after {
  clear: both;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a {
  padding: 10px;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a > h3 {
  font-size: 14px;
  padding: 0;
  margin: 0 0 10px 0;
  color: #666666;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a > .progress {
  padding: 0;
  margin: 0;
}
.navbar-nav > .user-menu > .dropdown-menu {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 1px 0 0 0;
  border-top-width: 0;
  width: 285px;
}
.navbar-nav > .user-menu > .dropdown-menu,
.navbar-nav > .user-menu > .dropdown-menu > .user-body {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  border-radius: 10px 10px 0px 0px;
  margin: 0;
  background: #1b1c20;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > ul>li>a > div > img {
    margin: -4px 5px;
    width: 35px;
    height: 35px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > ul>li>a > h4 {
    padding: 0;
    margin: 8px 0 0 62px;
    color: #fff;
    font-size: 15px;
    position: relative;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
  z-index: 5;
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  font-size: 17px;
  margin-top: 10px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p > small {
  display: block;
  font-size: 12px;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body {
  padding: 15px;
  border-bottom: 1px solid #f4f4f4;
  border-top: 1px solid #dddddd;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body:before,
.navbar-nav > .user-menu > .dropdown-menu > .user-body:after {
  content: " ";
  display: table;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body:after {
  clear: both;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body a {
  color: #444 !important;
}
@media (max-width: 991px) {
  .navbar-nav > .user-menu > .dropdown-menu > .user-body a {
    background: #fff !important;
    color: #444 !important;
  }
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer {
  background-color: #f9f9f9;
  padding: 10px;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer:before,
.navbar-nav > .user-menu > .dropdown-menu > .user-footer:after {
  content: " ";
  display: table;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer:after {
  clear: both;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default {
  color: #666666;
}
@media (max-width: 991px) {
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
    background-color: #f9f9f9;
  }
}
.navbar-nav > .user-menu .user-image {
  float: left;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -2px;
}
@media (max-width: 767px) {
  .navbar-nav > .user-menu .user-image {
    float: none;
    margin-right: 0;
    margin-top: -8px;
    line-height: 10px;
  }
}
@media (max-width: 767px) {
  .box{
    margin-top: 10%;
  }
}
/* Add fade animation to dropdown menus by appending
 the class .animated-dropdown-menu to the .dropdown-menu ul (or ol)*/
.open:not(.dropup) > .animated-dropdown-menu {
  backface-visibility: visible !important;
  -webkit-animation: flipInX 0.7s both;
  -o-animation: flipInX 0.7s both;
  animation: flipInX 0.7s both;
}
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
  }
}
/* Fix dropdown menu in navbars */
.navbar-custom-menu > .navbar-nav > li {
  position: relative;
}
.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
}
@media (max-width: 991px) {
  .navbar-custom-menu > .navbar-nav {
    float: right;
  }
  .navbar-custom-menu > .navbar-nav > li {
    position: static;
  }
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    position: absolute;
    right: 5%;
    left: auto;
    border: 1px solid #ddd;
    background: #fff;
  }
}
.skin-blue .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-blue .main-header .navbar .nav > li > a:hover,
.skin-blue .main-header .navbar .nav > li > a:active,
.skin-blue .main-header .navbar .nav > li > a:focus,
.skin-blue .main-header .navbar .nav .open > a,
.skin-blue .main-header .navbar .nav .open > a:hover,
.skin-blue .main-header .navbar .nav .open > a:focus,
.skin-blue .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}

@media (max-width: 767px) {
  .skin-blue .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-blue .main-header .navbar .dropdown-menu li a {
    color: #fff;
    background:#1b1c20;
  }
  .skin-blue .main-header .navbar .dropdown-menu li a:hover {
    background: #367fa9;
  }
}
.skin-blue .main-header .logo {
  background-color: var(--blue-background) !important;
  /*background-color: #2c3245;*/
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-blue .main-header li.user-header {
  background-color: #3c8dbc;
}
.skin-blue .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background: #fff;
  color: #367ea8!important; 
  /*color: #e60004;*/
  /*background: #fff;*/
}
.skin-blue .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: #fff;
}

.skin-blue .sidebar-menu .treeview-menu > li.active > a,
.skin-blue .sidebar-menu .treeview-menu > li > a:hover {
  color: #ffffff;
}
 .hold-transition .right-side,
 .hold-transition .main-footer,
 .hold-transition .main-sidebar,
 .hold-transition .left-side,
 .hold-transition .main-header .navbar,
 .hold-transition .main-header .logo,
 .hold-transition .menu-open .fa-angle-left {
   /* Fix for IE */
   -webkit-transition: 0.5s;
   -o-transition: 0.5s;
   transition: 0.5s;
 }
.subheaderbg{
  background-color: #fff;
  height: 40px;
  text-align: center;
  color: #000;
}
.headerbg{
  background-color: #2b3245;
  height: 50px;
}

.skin-blue .main-header .navbar {
  background-color: #1b1c20;
}

.source{
  /*font-weight: 600;*/
  margin-top: 0px;
  /*font-size: 21px;*/
  padding-top: 0.6%;
  letter-spacing: 1px;
  font-family: 'MyriadPro-Bold';

}
.us{
  margin: auto 10px auto auto;
  width: 25px;
  height: 25px;
}
.msg {
  position: relative;
}
.menu1 {
  max-height: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
}
.tit {
  padding: 0;
  margin: 0 0 0 45px;
  color: #fff;
  font-size: 13px;
  position: relative;
}
.why{
  margin: 0 0 0 45px;
  font-size: 12px;
  color: #fff;
}
.bord{
  background:#2b3245; 
  margin: 0;
  padding: 10px 10px;
  display: block;
  white-space: nowrap;
  /*border-bottom: 1px solid #f4f4f4;*/
}
.main-header .sidebar-toggle {
  float: left;
  background-color: transparent;
  background-image: none;
  padding: 10px 15px;
  font-family: 'Fontawesome';
  color: #2b3245;
  margin-top: 7px;
}
.online{
  background: green;
  border-radius: 50%;
  padding: 5px 5px;
  margin-left: 10px;
}
.pdli{
  padding: 15px 15px !important;
}
.bgbtn a{
  color: #fff;
  margin-top: 5px;
  background: #040410;
  font-size: 11px;
  padding: 4px 15px;
  border-radius: 10px;
}
.bgbtn{
  margin-top: 3px;
}
.logocr{
  height: 32px;
}

.sidebarmrtop{
  margin-top: 15px !important;
}
.arrow-up{
  margin-left: 84%;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #1b1c20;
}
.bordred{
  height: 5px;
  background:#e60004;
  border-radius: 0 0 10px 10px; 
}
.profilefoot{
  padding: 8px 7px;
  background: #2b3245;
  height: 37px;
}
.dropmenu{
  box-shadow: none;
  border-color: #fff;
}
.imgcss{
  border: 2px solid #eee;
  border-radius: 50px;
  padding-top: 0.5em;
  padding-bottom: .75em;
}
.angleright{
  margin-right: 14px;
}
.logoOne{
    height: 65px;
    margin-top: 0px;
    padding-top: 6px;
}
.profileTitle{
  cursor: pointer;
}
.marginTop11 {
    margin-top: 1%;
}
.badgeClass{
  position: absolute;
    top: 11px;
    left: 26px;
    font-size: 10px !important;
}